import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Col, Divider, Form, Input, List, Modal, Popconfirm, Row, Select, Spin, notification } from 'antd';

import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons';


import image from '../../1695793881259.jpeg'
import TextArea from 'antd/es/input/TextArea';

import './style.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { deleteCourse, deleteCourseContent, editCourse, getCourseById, getCourseContentByRoleAndCourseId } from '../../../../../Constants/Api/functions/function';
import AddContent from './AddContent';
import EditContent from './EditContent';



const { Option } = Select;

const EditCourse = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState();
    const [selectedContentData, setSelectedContentData] = useState();
    const [openEdit, setOpenEdit] = useState(false);
    const [isAddContentOpen, setisAddContentOpen] = useState(false);
    const [courseData, setCourseData] = useState();
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const [data, setData] = useState();
    const courseId = useParams().id
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const { confirm } = Modal;
    const fromRef = useRef();

    useEffect(() => {
        if (userProfile?.role !== 'superadmin' && userProfile?.role !== 'admin') {
            window.location.href = '/dashboard/home'
        }
    }, [userProfile])

    useEffect(() => {
        if (allCources?.length > 0) {
            const course = allCources.find(course => course.id === courseId);
            setCourseData(course);
        } else {
            handelGetCourseById();
        }
    }, [allCources, courseId]);

    useEffect(() => {
        getCourseContents();
    }, [courseData]);

    useEffect(() => {
        const content = data?.find(content => content.id === selectedContentId);
        setSelectedContentData(content);
    }, [selectedContentId]);

    useEffect(() => {
        if (courseData && fromRef.current) {
            fromRef.current.setFieldsValue({
                nameEnglish: courseData.nameEnglish,
                nameSinhala: courseData.nameSinhala,
                nameTamil: courseData.nameTamil,
                shortDiscriptionEnglish: courseData.shortDiscriptionEnglish,
                shortDiscriptionSinhala: courseData.shortDiscriptionSinhala,
                shortDiscriptionTamil: courseData.shortDiscriptionTamil,
                coverImageURLEnglish: courseData.coverImageURLEnglish,
                coverImageURLSinhala: courseData.coverImageURLSinhala,
                coverImageURLTamil: courseData.coverImageURLTamil,
                startDate: courseData.startDate,
                isPublished: courseData.isPublished ? 'true' : 'false'
            });
        }
    }, [courseData]);

    const showAddContent = () => {
        setisAddContentOpen(true);
    };

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        values.id = courseId;

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        setIsLoading(true);

        try {
            const res = await editCourse(values);
            if (res && res.status === 200) {
                openEditNotification('success',
                    'Course updated successfully',
                    'The course has been updated successfully'
                );
                handelGetCourseById();
            } else {
                openEditNotification('error',
                    'Course update failed',
                    'The course update failed'
                );
            }
        }
        catch (error) {
            console.error('editCourse', error);
        }

        setIsLoading(false);
    }

    const deleteContent = async (id) => {
        setIsLoading(true);
        try {
            const response = await deleteCourseContent(id);
            if (response && response.status === 200) {
                openDeleteNotification('success',
                    'Course content deleted successfully',
                    'The course content has been deleted successfully'
                );
                await getCourseContents();
            } else {
                openDeleteNotification('error',
                    'Course content deletion failed',
                    'The course content deletion failed'
                );
            }
        } catch (error) {
            console.error('deleteCourse', error);
            openDeleteNotification('error',
                'Course content deletion failed',
                `The course content deletion failed. ${error}`
            );
        }
        setIsLoading(false);
    }

    const handelGetCourseById = async () => {
        try {
            const response = await getCourseById(courseId);
            setCourseData(response[0]);
        } catch (error) {
            console.error('handelGetCourseById', error);
        }
    }

    const getCourseContents = async () => {
        setIsLoading(true);
        try {
            const response = await getCourseContentByRoleAndCourseId(courseId, userProfile.role, userProfile.id);
            if (response) {
                setData(response.map((item, index) => {
                    return {
                        id: item.id,
                        titleSinhala: item.titleSinhala,
                        titleTamil: item.titleTamil,
                        titleEnglish: item.titleEnglish,
                        shortDiscriptionEnglish: item.shortDiscriptionEnglish,
                        shortDiscriptionSinhala: item.shortDiscriptionSinhala,
                        shortDiscriptionTamil: item.shortDiscriptionTamil,
                        createdAt: item.createdAt,
                        videoURLEnglish: item.videoURLEnglish,
                        videoURLSinhala: item.videoURLSinhala,
                        videoURLTamil: item.videoURLTamil,
                        driveURLEnglish: item.driveURLEnglish,
                        driveURLSinhala: item.driveURLSinhala,
                        driveURLTamil: item.driveURLTamil,
                        isPublished: item.isPublished,
                    }
                }))

                setSelectedContentId(response[0].id);
            }
        } catch (error) {
            console.error('getCourseContents', error);
        }
        setIsLoading(false);
    }

    const handleDeleteCourse = async () => {
        setIsLoading(true);
        try {
            const response = await deleteCourse(courseId);
            if (response && response.status === 200) {
                openDeleteNotification('success',
                    'Course deleted successfully',
                    'The course has been deleted successfully'
                );

                setTimeout(() => {
                    window.location.href = '/dashboard/cources';
                }, 2000);

            } else {
                openDeleteNotification('error',
                    'Course deletion failed',
                    'The course deletion failed'
                );
            }
        } catch (error) {
            console.error('deleteCourse', error);
            openDeleteNotification('error',
                'Course deletion failed',
                `The course deletion failed. ${error}`
            );
        }
        setIsLoading(false);
    }

    const showDeleteConfirm = async () => {
        confirm({
            title: 'Are you sure delete this course?',
            icon: <ExclamationCircleFilled />,
            content: 'This action cannot be undone. Are you sure you want to delete this course? If you delete this course, all the contents of this course will be deleted.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                await handleDeleteCourse(courseId);
            },
        });
    };

    const openDeleteNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const openEditNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div className='edit-course'>
            <Spin spinning={isLoading}
                size="large"
                tip="Loading..."
            >
                {contextHolder}
                <div className='edit-header'>
                    <h1>Edit Course</h1>
                    <Button
                        type='primary'
                        danger
                        className='btn'
                        onClick={showDeleteConfirm}
                    >
                        <DeleteOutlined />
                        <span>Delete Course</span>
                    </Button>
                </div>
                <div className='edit-body'>
                    <div className='edit-left'>
                        <div className='thumbnail'>
                            <img
                                src={courseData?.coverImageURLEnglish || image}
                                alt='thumbnail' />
                        </div>
                        <div className='edit-course'
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <div className='drawer-header'>
                                <p>
                                    Please select languages for editing the course.
                                </p>
                                <Checkbox
                                    defaultChecked
                                    disabled
                                >
                                    English
                                </Checkbox>
                                <Checkbox
                                    onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                                >
                                    Sinhala
                                </Checkbox>
                            </div>
                            <Form
                                layout="vertical"
                                onFinish={onFinish}
                                ref={fromRef}
                            >
                                <Form.Item
                                    name="nameEnglish"
                                    label="Course Name English"
                                >
                                    <Input placeholder="Please enter course's name in English" />
                                </Form.Item>
                                <Form.Item
                                    name="shortDiscriptionEnglish"
                                    label="Course Short Description English"
                                >
                                    <TextArea placeholder="Please enter course's short description in English" />
                                </Form.Item>
                                <Form.Item
                                    name="coverImageURLEnglish"
                                    label="Course cover image English"
                                >
                                    <Input placeholder="Please enter course's cover image in English" />
                                </Form.Item>
                                <Form.Item
                                    name="startDate"
                                    label="Course Start Date"
                                >
                                    <Input type='date' placeholder="Please enter course's start date" />
                                </Form.Item>
                                <Form.Item
                                    name="isPublished"
                                    label="Course is Published"
                                >
                                    <Select
                                        placeholder="Please select course's published status"
                                    >
                                        <Option value="true">Published</Option>
                                        <Option value="false">Not Published</Option>
                                    </Select>
                                </Form.Item>



                                {isSinhalaEnabled && <>
                                    <Divider>Sinhala</Divider>
                                    <Form.Item
                                        name="nameSinhala"
                                        label="Course Name Sinhala"
                                    >
                                        <Input placeholder="Please enter course's name in Sinhala" />
                                    </Form.Item>
                                    <Form.Item
                                        name="shortDiscriptionSinhala"
                                        label="Course Short Description Sinhala"
                                    >
                                        <TextArea placeholder="Please enter course's short description in Sinhala" />
                                    </Form.Item>
                                    <Form.Item
                                        name="coverImageURLSinhala"
                                        label="Course cover image Sinhala"
                                    >
                                        <Input placeholder="Please enter course's cover image in Sinhala" />
                                    </Form.Item>
                                </>
                                }



                                {/* <Form.Item
                                    name="nameTamil"
                                    label="Course Name Tamil"
                                >
                                    <Input placeholder="Please enter course's name in Tamil" />
                                </Form.Item>
                                <Form.Item
                                    name="shortDiscriptionTamil"
                                    label="Course Short Description Tamil"
                                >
                                    <TextArea
                                        placeholder="Please enter course's short discription in Tamil" />
                                </Form.Item>
                                <Form.Item
                                    name="coverImageURLTamil"
                                    label="Course cover image Tamil"
                                >
                                    <Input placeholder="Please enter course's cover image in Tamil" />
                                </Form.Item> */}

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Button
                                            style={{ width: '100%' }}
                                            type="primary" htmlType="submit">
                                            Update Course
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <div className='edit-right'>
                        <div className='top'>
                            <h2>Course Content</h2>
                            <Button
                                onClick={showAddContent}
                                type='primary'><PlusOutlined /> Add Content</Button>
                        </div>
                        <div className='content'>
                            <List
                                size="small"
                                bordered
                                dataSource={data}
                                renderItem={item =>
                                    <List.Item
                                        style={{
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Row
                                            gutter={16}
                                        >
                                            <div className='no-edit'>
                                                <section>
                                                    <p>Title English:</p>
                                                    <span>
                                                        {item.titleEnglish || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Short Description English:</p>
                                                    <span>
                                                        {item.shortDiscriptionEnglish || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Video URL English:</p>
                                                    <span>
                                                        {item.videoURLEnglish || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Drive URL English:</p>
                                                    <span>
                                                        {item.driveURLEnglish || 'N/A'}
                                                    </span>
                                                </section>

                                                <section>
                                                    <p>Title Sinhala:</p>
                                                    <span>
                                                        {item.titleSinhala || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Short Description Sinhala:</p>
                                                    <span>
                                                        {item.shortDiscriptionSinhala || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Video URL Sinhala:</p>
                                                    <span>
                                                        {item.videoURLSinhala || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Drive URL Sinhala:</p>
                                                    <span>
                                                        {item.driveURLSinhala || 'N/A'}
                                                    </span>
                                                </section>


                                                {/* <section>
                                                    <p>Title Tamil:</p>
                                                    <span>
                                                        {item.titleTamil || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Short Description Tamil:</p>
                                                    <span>
                                                        {item.shortDiscriptionTamil || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Video URL Tamil:</p>
                                                    <span>
                                                        {item.videoURLTamil || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Drive URL Tamil:</p>
                                                    <span>
                                                        {item.driveURLTamil || 'N/A'}
                                                    </span>
                                                </section> */}


                                                <section>
                                                    <p>Created At:</p>
                                                    <span>
                                                        {item.createdAt ? new Date(item.createdAt).toDateString() : 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Is Published:</p>
                                                    <span
                                                        style={{
                                                            color: item.isPublished ? 'green' : 'red'
                                                        }}
                                                    >
                                                        {item.isPublished ?
                                                            'Published'
                                                            : 'Not Published'}
                                                    </span>
                                                </section>
                                            </div>
                                        </Row>

                                        <Row
                                            gutter={16}
                                        >
                                            <Col>
                                                <Button
                                                    onClick={
                                                        () => {
                                                            setOpenEdit(true);
                                                            setSelectedContentId(item.id);
                                                        }
                                                    }
                                                    style={{ width: '100%' }}
                                                    type="default"
                                                >
                                                    Edit
                                                </Button>
                                            </Col>

                                            <Col >
                                                <Popconfirm
                                                    title="Delete the content."
                                                    description="This action cannot be undone. Are you sure you want to delete this content?"
                                                    onConfirm={deleteContent.bind(this, item.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        type="default"
                                                        danger>
                                                        <DeleteOutlined />
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }
                            />
                        </div>
                    </div>
                </div>
            </Spin>

            <AddContent
                isOpen={isAddContentOpen}
                setOpen={setisAddContentOpen}
                courseId={courseId}
                hadleGetCourceContents={getCourseContents}
            />

            <EditContent
                isOpen={openEdit}
                setOpen={setOpenEdit}
                contentData={selectedContentData}
                courseId={courseId}
                hadleGetCourceContents={getCourseContents}
            />
        </div>
    )
}

export default EditCourse