export const DISTRICTS = [
    { id: 1, name: 'Colombo', val: 'colombo' },
    { id: 2, name: 'Gampaha', val: 'gampaha' },
    { id: 3, name: 'Kalutara', val: 'kalutara' },
    { id: 4, name: 'Kandy', val: 'kandy' },
    { id: 5, name: 'Matale', val: 'matale' },
    { id: 6, name: 'Nuwara Eliya', val: 'nuwaraeliya' },
    { id: 7, name: 'Galle', val: 'galle' },
    { id: 8, name: 'Matara', val: 'matara' },
    { id: 9, name: 'Hambantota', val: 'hambantota' },
    { id: 10, name: 'Jaffna', val: 'jaffna' },
    { id: 11, name: 'Kilinochchi', val: 'kilinochchi' },
    { id: 12, name: 'Mannar', val: 'mannar' },
    { id: 13, name: 'Mullaitivu', val: 'mullaitivu' },
    { id: 14, name: 'Vavuniya', val: 'vavuniya' },
    { id: 15, name: 'Puttalam', val: 'puttalam' },
    { id: 16, name: 'Kurunegala', val: 'kurunegala' },
    { id: 17, name: 'Anuradhapura', val: 'anuradhapura' },
    { id: 18, name: 'Polonnaruwa', val: 'polonnaruwa' },
    { id: 19, name: 'Badulla', val: 'badulla' },
    { id: 20, name: 'Monaragala', val: 'monaragala' },
    { id: 21, name: 'Ratnapura', val: 'ratnapura' },
    { id: 22, name: 'Kegalle', val: 'kegalle' },
    { id: 23, name: 'Trincomalee', val: 'trincomalee' },
    { id: 24, name: 'Batticaloa', val: 'batticaloa' },
    { id: 25, name: 'Ampara', val: 'ampara' },
]