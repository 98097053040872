import React, { useEffect, useState } from 'react'

import {
    Button,
    Modal,
    Form,
    Row,
    Col,
    Spin,
    message,
    Divider,
    Popconfirm,
    notification,
    Checkbox
} from 'antd'
import {
    PlusOutlined,
    DeleteOutlined,
} from '@ant-design/icons'

// Redux
import { useDispatch, useSelector } from 'react-redux';

// API 
import { addNote, deleteNote, getNotes } from '../../../Constants/Api/functions/function';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss'
import { setNote } from '../../../Reducers/noteReducer';

import male from '../../../Assets/Images/male.png'
import female from '../../../Assets/Images/female.png'


const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const Home = () => {
    const [api, contextHolderNoti] = notification.useNotification();
    const [loadingNote, setLoadingNote] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSinhalaEnabled, setIsSinhalaEnabled] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const note = useSelector(state => state.note.note);
    const language = localStorage.getItem('language');
    const dispatch = useDispatch();


    useEffect(() => {
        if (!note) {
            handleGetNote();
        }
    }, [note])

    const handleGetNote = async () => {
        setLoadingNote(true);
        try {
            const response = await getNotes();
            dispatch(setNote(response));
        } catch (error) {
            console.error('handleGetNote', error);
        }
        setLoadingNote(false);
    }

    const handleDeleteNote = async () => {
        setLoadingNote(true);
        try {
            const response = await deleteNote('NOTE');
            if (response && response.status === 200) {
                openNotification('success',
                    'Success',
                    'Note deleted successfully'
                );
                await handleGetNote();
            } else {
                openNotification('error',
                    'Error',
                    response?.data?.message
                );
            }
        } catch (error) {
            console.error('handleDeleteNote', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setLoadingNote(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinishNote = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        setLoadingNote(true);

        try {
            const res = await addNote(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New note added successfully'
                );
                setIsModalOpen(false);
                await handleGetNote();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setLoadingNote(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div className='home'>
            {contextHolderNoti}
            <div className='home-left-top'>
                <div className='bg-img'>
                </div>
                <div className='profile-img'>
                    <img
                        src={userProfile?.gender === 'male' ? male : female}
                        alt='profile'
                    />
                </div>
                <div className='profile-name'>
                    <h1>
                        {userProfile?.name}
                    </h1>
                    <h2>
                        {userProfile?.email}
                    </h2>
                </div>
            </div>
            <div className='home-continer'>
                <div className='home-right'>
                    <Spin
                        spinning={loadingNote}
                        tip="Please wait..."
                    >
                        <div className='special-notes'>
                            {userProfile?.role === 'superadmin' &&
                                <div className='note-header'>
                                    {note &&
                                        <div style={{
                                            marginRight: '10px'
                                        }}>
                                            <Popconfirm
                                                title="Are you sure to delete this note?"
                                                onConfirm={handleDeleteNote}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type='link'
                                                    danger
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    }
                                    <Button
                                        onClick={() => setIsModalOpen(true)}
                                        type='primary'
                                        className='add-btn'
                                    >
                                        <PlusOutlined />
                                    </Button>
                                </div>}
                            <h2 className='title'>
                                {language === 'tamil' ? 'சிறப்பு குறிப்புகள்' : language === 'sinhala' ? 'විශේෂ සටහන්' : 'Special Notes'}
                            </h2>
                            <ReactQuill
                                className='quill'
                                readOnly
                                theme="bubble"
                                value={language === 'tamil' && note?.noteTamil ? note?.noteTamil : (language === 'sinhala' && note?.noteSinhala ? note?.noteSinhala : note?.noteEnglish)}
                            />
                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title="Add new note"
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <div className='drawer-header'>
                    <p>
                        Please select the language for the note.
                    </p>
                    <Checkbox
                        defaultChecked
                        disabled
                    >
                        English
                    </Checkbox>
                    <Checkbox
                        onChange={(e) => setIsSinhalaEnabled(e.target.checked)}
                    >
                        Sinhala
                    </Checkbox>
                </div>
                <Spin spinning={loadingNote} tip="Please wait...">
                    <Form
                        layout="vertical"
                        onFinish={onFinishNote}
                    >
                        <Form.Item
                            name="noteEnglish"
                            label="Note English"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter content description",
                                },
                            ]}
                        >
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The content starts here..."
                                style={{
                                    height: '100%'
                                }}
                            />
                        </Form.Item>

                        {isSinhalaEnabled &&
                            <>
                                <Divider>Sinhala</Divider>
                                <Form.Item
                                    name="noteSinhala"
                                    label="Note Sinhala"
                                >
                                    <ReactQuill
                                        modules={modules}
                                        theme="snow"
                                        placeholder="The content starts here..."
                                        style={{
                                            height: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </>
                        }

                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit">
                                    Add Note
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}

export default Home