import {
    HomeOutlined,
    TeamOutlined,
    BookOutlined,
    BankOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";

const SUPER_ADMIN_MENU_ITEMS = [
    {
        key: "1", icon: <HomeOutlined />,
        label: "Home", labelSinhala: "මුල් පිටුව", labelTamil: "முகப்பு பக்கம்",
        href: "/dashboard/home"
    },
    {
        key: "2", icon: <TeamOutlined />,
        label: "Users", labelSinhala: "පරිශීලකයින්", labelTamil: "பயனர்கள்",
        href: "/dashboard/users"
    },
    {
        key: "3", icon: <BookOutlined />,
        label: "Courses", labelSinhala: "පාඨමාලා", labelTamil: "படிப்புகள்",
        href: "/dashboard/cources"
    },
    {
        key: "4", icon: <BankOutlined />,
        label: "Libraries", labelSinhala: "පුස්තකාල", labelTamil: "நூலகங்கள்",
        href: "/dashboard/libraries"
    },
    {
        key: "5", icon: <FileDoneOutlined />,
        label: "Assessments", labelSinhala: "ඇගයීම්", labelTamil: "மதிப்பீடுகள்",
        href: "/dashboard/assessments"
    },
]

const ADMIN_MENU_ITEMS = [
    {
        key: "1", icon: <HomeOutlined />,
        label: "Home", labelSinhala: "මුල් පිටුව", labelTamil: "முகப்பு பக்கம்",
        href: "/dashboard/home"
    },
    {
        key: "2", icon: <TeamOutlined />,
        label: "Users", labelSinhala: "පරිශීලකයින්", labelTamil: "பயனர்கள்",
        href: "/dashboard/users"
    },
    {
        key: "3", icon: <BookOutlined />,
        label: "Courses", labelSinhala: "පාඨමාලා", labelTamil: "படிப்புகள்",
        href: "/dashboard/cources"
    },
    {
        key: "4", icon: <BankOutlined />,
        label: "Libraries", labelSinhala: "පුස්තකාල", labelTamil: "நூலகங்கள்",
        href: "/dashboard/libraries"
    },
    {
        key: "5", icon: <FileDoneOutlined />,
        label: "Assessments", labelSinhala: "ඇගයීම්", labelTamil: "மதிப்பீடுகள்",
        href: "/dashboard/assessments"
    },
]

const STUDENT_MENU_ITEMS = [
    {
        key: "1", icon: <HomeOutlined />,
        label: "Home", labelSinhala: "මුල් පිටුව", labelTamil: "முகப்பு பக்கம்",
        href: "/dashboard/home"
    },
    {
        key: "3", icon: <BookOutlined />,
        label: "Courses", labelSinhala: "පාඨමාලා", labelTamil: "படிப்புகள்",
        href: "/dashboard/cources"
    },
    {
        key: "4", icon: <BankOutlined />,
        label: "Library", labelSinhala: "පුස්තකාල", labelTamil: "நூலகங்கள்",
        href: "/dashboard/libraries"
    },
    {
        key: "5", icon: <FileDoneOutlined />,
        label: "Assessments", labelSinhala: "ඇගයීම්", labelTamil: "மதிப்பீடுகள்",
        href: "/dashboard/assessments"
    },
]

export {
    SUPER_ADMIN_MENU_ITEMS,
    ADMIN_MENU_ITEMS,
    STUDENT_MENU_ITEMS,
}
