import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import Landing from './Pages/LandingPage'
import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login';
import Register from './Pages/Register';

import history from '../history'
import FogetPassword from './Pages/FogetPassword';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={FogetPassword} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
