import React, { useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin } from 'antd';
import { checkIsEmailExist, createUser } from '../../../../Constants/Api/functions/function';

const { Option } = Select;

const AddUser = ({ setOpen, isOpen, handleGetAllUsers, centers }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        try {
            setIsLoading(true)

            const isEmailExist = await checkIsEmailExist(values.email)

            if (isEmailExist) {
                emailExistNotification()
                setIsLoading(false)
            } else {
                const response = await createUser(values)
                if (response && response?.status === 201) {
                    openNotificationWithIcon("success")
                    closeAddUserDrawer()
                    await handleGetAllUsers()
                } else {
                    console.log("error", response?.data?.message)
                    openNotificationWithIcon("error", response?.data?.message)
                }
            }
        } catch (error) {
            console.log("error", error)
            openNotificationWithIcon("error", error)
        }
        setIsLoading(false)
    }

    const openNotificationWithIcon = (type, error) => {
        api[type]({
            message: type === "success" ? "User Created Successfully" : "Error Creating User",
            description: type === "success" ? "User has been created successfully. Email has been sent to the user with login credentials."
                : `There was an error creating user. Please try again later. ${error}`
        });
    };

    const emailExistNotification = () => {
        api['error']({
            message: "Email already exist",
            description: "The email you entered already exist. Please enter a different email."
        });
    }


    const closeAddUserDrawer = () => {
        setOpen(false)
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Create new user"
                width={720}
                onClose={closeAddUserDrawer}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Creating user..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="User Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter user's name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter user's name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter user email',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The email is not a valid!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Please enter user email" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    name="dateOfBirth"
                                    label="Date of Birth"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your date of birth!',
                                        },
                                    ]}
                                >
                                    <Input type="date" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    name="address"
                                    label="Home Address"
                                >
                                    <Input placeholder="Enter your home address" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    label="Contact Number"
                                    name="contactNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your contact number!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^0[0-9]{9}$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="0XX XXXX XXX" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    name="centerId"
                                    label="School"
                                    rules={[
                                        {
                                            required: !isSuperAdmin,
                                            message: 'Please input your school!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a School"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {centers.map(center => (
                                            <Option value={center.id} key={center.id}>{center.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    label="Gender"
                                    name="gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your gender!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select your gender"
                                        allowClear
                                    >
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className='form-item'
                                    label="Role"
                                    name="role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select user role!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select user role"
                                        allowClear
                                        onChange={(value) => {
                                            if (value === "superadmin") {
                                                setIsSuperAdmin(true)
                                            } else {
                                                setIsSuperAdmin(false)
                                            }
                                        }}
                                    >
                                        <Option value="superadmin">Super Admin</Option>
                                        <Option value="student">Student</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    Add User
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddUser