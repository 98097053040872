import React from 'react';
import ReactDOM from 'react-dom/client';

import { ConfigProvider } from 'antd';
import theme from './theme/theme.json';

// Redux
import store from './store'
import { Provider } from 'react-redux'

import './index.css';
import App from './App/App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);
