import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { List, Spin } from 'antd';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

import nodata from '../../../../Assets/Images/nodata.png';
import video from '../../../../Assets/Images/video.png';
import files from '../../../../Assets/Images/file.png';

import './style.scss';

// json
import courses from '../courses.json';


const Course = () => {
    const [isLoading, setIsLoading] = useState(false);
    const courseId = useParams().id
    const [selectedContentId, setSelectedContentId] = useState();
    const language = localStorage.getItem('language');
    const [courseContents, setCourseContents] = useState();
    const [data, setData] = useState()

    useEffect(() => {
        getCourseContents();
    }, [])


    const getCourseContents = async () => {
        setIsLoading(true);
        try {
            let response = courses.find(item => item.id === courseId).content;
            if (response) {
                setCourseContents(response);

                response.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });

                setData(response.map((item, index) => {
                    return {
                        key: index,
                        id: item.id,
                        title: item.title,
                    }
                }))
                setSelectedContentId(response[0]?.id);
            }
        } catch (error) {
            console.error('getCourseContents', error);
        }
        setIsLoading(false);
    }

    return (
        <div className='course'>
            <Spin
                spinning={isLoading}
                tip="Loading..."
                size="large">
                <div className='course-continer'>
                    {(isLoading || !courseContents?.length)
                        ?
                        <div className='no-data'>
                            {!isLoading &&
                                <div>
                                    <img
                                        src={nodata}
                                        alt='No Data' />
                                    <h1>
                                        {language === 'sinhala' ? 'පාඨමාලාව ඇතුළත් කර නොමැත' : language === 'tamil' ? 'பாடநெறி சேர்க்கப்படவில்லை' : 'Course Content Will be Available Soon'}
                                    </h1>
                                </div>}
                        </div>
                        :
                        <>
                            <div className='body'>
                                <div className='course-left'>
                                    <h1 className='content-title'>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                if (selectedContent) {
                                                    return selectedContent?.title
                                                } else {
                                                    return ""
                                                }
                                            })()
                                        }
                                    </h1>
                                    {
                                        (() => {
                                            const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                            if (!selectedContent) {
                                                return <h1>
                                                    {language === 'sinhala' ? 'වීඩියෝ නොමැත' : language === 'tamil' ? 'வீடியோ இல்லை' : 'No video available'}
                                                </h1>
                                            } else {
                                                return selectedContent.videoURL ?
                                                    <iframe
                                                        src={selectedContent.videoURL}
                                                        title="YouTube video player"
                                                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                                                    </iframe>
                                                    :
                                                    <div className='no-data-mini'>
                                                        {!isLoading &&
                                                            <div>
                                                                <img
                                                                    src={video}
                                                                    alt='No Data' />
                                                                <h1>
                                                                    {language === 'sinhala' ? 'වීඩියෝ නොමැත' : language === 'tamil' ? 'வீடியோ இல்லை' : 'Video Will be Available Soon'}
                                                                </h1>
                                                            </div>
                                                        }
                                                    </div>
                                            }
                                        })()
                                    }
                                    <h2>{language === 'sinhala' ? 'මෙම පාඨමාලා පිළිබඳව' : language === 'tamil' ? 'இந்த பாடநெறி பற்றி' : 'About This Lesson'}</h2>
                                    <p>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);

                                                if (!selectedContent) { return '' }
                                                else {
                                                    return selectedContent?.description
                                                }
                                            })
                                        }
                                    </p>

                                    <h2>{language === 'sinhala' ? 'පාඨමාලා ලේඛන' : language === 'tamil' ? 'பாட ஆவணங்கள்' : 'Course Materials'}</h2>
                                    <div className='drive-floder'>
                                        {
                                            (() => {
                                                const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                if (!selectedContent) {
                                                    return <h1>
                                                        {language === 'sinhala' ? 'ගූගල් ෆෝල්ඩරය නොමැත' : language === 'tamil' ? 'கூகுள் படையெடு இல்லை' : 'No Drive Folder available'}
                                                    </h1>
                                                } else {
                                                    return selectedContent.driveURL ?
                                                        <iframe
                                                            className='drive-iframe'
                                                            title="Google Drive Folder"
                                                            src={selectedContent.driveURL}>
                                                        </iframe>
                                                        :
                                                        <div className='no-data-mini'>
                                                            {!isLoading &&
                                                                <div>
                                                                    <img
                                                                        src={files}
                                                                        alt='No Data' />
                                                                    <h1>
                                                                        {language === 'sinhala' ? 'ගූගල් ෆෝල්ඩරය නොමැත' : language === 'tamil' ? 'கூகுள் படையெடு இல்லை' : 'Files Will be Available Soon'}
                                                                    </h1>
                                                                </div>
                                                            }
                                                        </div>
                                                }
                                            })()
                                        }
                                    </div>
                                </div>

                                <div className='course-right'>
                                    <div className='course-content'>
                                        <h2>{language === 'sinhala' ? 'පාඨමාලා අන්තර්ගතය' : language === 'tamil' ? 'பாடநெறி உள்ளடக்கம்' : 'Course Contents'}</h2>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={data}
                                            renderItem={item =>
                                                <List.Item
                                                    className={selectedContentId === item.id ? 'active' : ''}
                                                    onClick={() => setSelectedContentId(item.id)}
                                                >
                                                    <div className='btn' type='link'>
                                                        {selectedContentId === item.id
                                                            ?
                                                            <PauseRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                            :
                                                            <PlayArrowRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='course-content-item'>
                                                        <p>{item.title}</p>
                                                    </div>
                                                </List.Item>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </Spin>
        </div>
    )
}

export default Course