import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, Drawer, Form, Input, Row, Select, Space, Divider, notification, Spin } from 'antd';
import { editUser } from '../../../../../../Constants/Api/functions/function';
import { useSelector } from 'react-redux';

const { Option } = Select;

const EditOverview = ({ open, setOpen, user, getUser }) => {
    const formRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector(state => state.userProfile.userProfile)

    useEffect(() => {
        if (user && formRef.current) {
            formRef.current.setFieldsValue({
                name: user.name,
                address: user.address,
                contactNumber: user.contactNumber,
                gender: user.gender,
                role: user.role,
                groupId: user.groupId
            })
        }
    }, [user, formRef, open])

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.id = user.id

        setIsLoading(true)
        try {
            const response = await editUser(values)
            if (response && response.status === 200) {
                openNotificationWithIcon('success')
                setOpen(false)
                getUser()
            }
        } catch (error) {
            openNotificationWithIcon('error', error)
        } finally {
            setIsLoading(false)
        }
    };

    const onClose = () => {
        setOpen(false);
    };


    const openNotificationWithIcon = (type, description) => {
        api[type]({
            message: type === 'success' ? 'Success' : 'Something went wrong',
            description: type === 'success' ? 'User profile updated successfully. Please refresh the page to see the changes'
                : `Error: ${description}`,
        });
    };

    return (
        <>
            {contextHolder}
            <Drawer
                title="Edit User Overview"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            form='edit-overview-form'
                            type="primary"
                            htmlType='submit'
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin
                    spinning={isLoading}
                    tip="Updating user profile..."
                >
                    <Form
                        id='edit-overview-form'
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="User Name"
                                >
                                    <Input placeholder="Please enter user name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter address"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="contactNumber"
                                    label="Contact Number"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^0[0-9]{9}$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="0XX XXXX XXX" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="gender"
                                    label="Gender"
                                >
                                    <Select
                                        placeholder="Please select gender"
                                    >
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {(userProfile?.role !== "student" || userProfile?.role === 'teacher') &&
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="role"
                                        label="User Role"
                                    >
                                        <Select
                                            placeholder="Please select user role"
                                        >
                                            <Option value="superadmin">Super Admin</Option>
                                            <Option value="student">Student</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>}

                        <Divider
                            style={{
                                marginTop: '0px',
                                marginBottom: '15px'
                            }}
                        />
                    </Form>
                </Spin>
            </Drawer >
        </>
    );
};
export default EditOverview;