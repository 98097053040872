import React, { useEffect, useState } from 'react';

import {
    List,
    Spin
} from 'antd';
import { Link } from 'react-router-dom';

// Styles
import './style.scss';

// json
import courses from './courses.json';

const Cources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const language = localStorage.getItem('language');
    const [coursesData, setCoursesData] = useState()

    useEffect(() => {
        setIsLoading(true);
        readCourseJSON();
        setIsLoading(false);
    }, []);

    const readCourseJSON = () => {
        setCoursesData(courses);
    }

    function trimDescription(description) {
        const words = description.split(' ');
        if (words.length > 40) {
            return words.slice(0, 40).join(' ') + '...';
        }
        return description;
    }

    return <div className='cources'>
        <div className='cources-action-buttons'>
            <h2>
                {language === 'tamil' ? 'அனைத்து பாடங்கள்' : language === 'sinhala' ? 'සියලුම පාඨමාලා' : 'All Courses'}
            </h2>
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Courses..."
        >
            <div className='cources-body'>
                <Spin
                    tip="Loading..."
                    spinning={isLoading}
                >
                    <List
                        grid={{
                            gutter: 30,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={coursesData}
                        renderItem={(item) => (
                            <List.Item>
                                <Link to={`/dashboard/cources/${item.id}`}>
                                    <div className='course-card'>
                                        <div className='card-image-container'>
                                            <img
                                                src={
                                                    item?.thumbnail
                                                        ? item?.thumbnail
                                                        : require('./1695793881259.jpeg')
                                                } alt=""
                                            />
                                            <div className='cource-card-header-buttons'>
                                                <div
                                                    className='duration'
                                                    style={{
                                                        backgroundColor: item?.isPublished ? '#4CAF50' : '#f44336'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            item?.isPublished ? 'Published' : 'Not Published'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <h3>
                                                {item?.name}
                                            </h3>
                                            <p>
                                                {trimDescription(item?.shortDiscription)}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </List.Item>
                        )}
                    />
                </Spin>
            </div>
        </Spin>
    </div>;
};
export default Cources;