import React, { useEffect, useRef, useState } from 'react'

import { Button, Divider, Form, Input } from 'antd'

import './style.scss'
import { attemptToAssestment, editAttempt, getUserById } from '../../../../../Constants/Api/functions/function'

const SubmitAssessment = ({
    assessmentData,
    handleGetAssessment,
    userProfile,
    contextHolder,
    api
}) => {
    const [attempts, setAttempts] = useState()
    const formRef = useRef();
    const language = localStorage.getItem('language');
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState();

    useEffect(() => {
        if (assessmentData) {
            if (!assessmentData?.attempt) {
                setAttempts(3)
            } else {
                setAttempts(3 - assessmentData?.attempt?.attemptNumber)
            }

            if (assessmentData?.attempt?.isReviewed) {
                renderName(assessmentData?.attempt?.reviewedBy).then(res => {
                    setName(res);
                })
            }
        }
    }, [assessmentData])

    const onFinish = async (values) => {
        values.userId = userProfile?.id;
        values.assestmentId = assessmentData?.id;

        setIsLoading(true);
        try {
            const response = await attemptToAssestment(values);
            if (response && response.status === 200) {
                openNotification('success', 'Assessment submitted successfully', '')
                formRef.current.resetFields();
                setTimeout(() => {
                    handleGetAssessment();
                }, 1000);
            } else {
                openNotification('error', 'Failed to submit assessment', '')
            }
        } catch (error) {
            console.log(error);
            openNotification('error', 'Failed to submit assessment', '')
        }
        setIsLoading(false);
    }
    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const onFinishReSubmit = async (values) => {
        values.id = assessmentData?.attempt?.id;
        values.userId = userProfile?.id;
        values.assestmentId = assessmentData?.id;

        setIsLoading(true);

        try {
            const response = await editAttempt(values);
            if (response && response.status === 200) {
                openNotification('success', 'Assessment re-submitted successfully', '')
                formRef.current.resetFields();
                setTimeout(() => {
                    handleGetAssessment();
                }, 1000);
            } else {
                openNotification('error', 'Failed to re-submit assessment', '')
            }
        }
        catch (error) {
            console.log(error);
            openNotification('error', 'Failed to re-submit assessment', '')
        }

        setIsLoading(false);
    }

    const renderName = async (id) => {
        try {
            const res = await getUserById(id);
            return res?.name;
        } catch (error) {
            console.log(error);
            return id;
        }
    }

    return (
        <div className='submit-assessment'>
            {contextHolder}
            <h2>{language === 'tamil' ? 'மதிப்பீட்டை சமர்ப்பித்தல்' : language === 'sinhala' ? 'ඇගයීම submit කිරීම' :
                'Submit Assessment'}</h2>

            <p>{!assessmentData?.attempt ?
                <span>
                    {language === 'tamil' ? 'இந்த மதிப்பீட்டை நீங்கள் இன்னும் சமர்ப்பிக்கவில்லை. உங்கள் மதிப்பீட்டைச் சமர்ப்பிக்கவும் ' : language === 'sinhala' ? 'ඔබ තවමත් මෙම ඇගයීම ඉදිරිපත් කර නැත. කරුණාකර ඔබේ ඇගයීම ඉදිරිපත් කරන්න ' :
                        'You have not submitted this assessment yet. Please submit your assessment '}
                    {` ${language === 'tamil' ? 'நிலுவைத் தேதிக்கு முன்' : language === 'sinhala' ? 'නියමිත දිනට පෙර' : 'before the due date:'
                        } ${assessmentData?.endDate}`}
                </span>
                :
                <>
                    {language === 'tamil' ? 'நீங்கள் இந்த மதிப்பீட்டை இதுவரை' : language === 'sinhala' ? 'ඔබ මෙම ඇගයීම තවමත්' : 'You have already attempted this assessment'}
                </>
            }
            </p>

            <h3>
                {`${language === 'tamil' ? 'உங்களுக்கு மேலும் வார' : language === 'sinhala' ? 'ඔබට තවත් වාර' : 'You have'
                    } ${attempts === 0 ? 'No' : attempts} ${language === 'tamil' ? 'முயற்சி செய்யலாம்' : language === 'sinhala' ? 'ක් උත්සාහ කිරීමට හැකියි' : 'attempts remaining.'
                    }`}
            </h3>

            <Divider />

            {assessmentData?.attempt && <div className='attempt-data'>
                <h4>
                    {language === 'tamil' ? 'உங்கள் சமர்ப்பிப்பு விவரங்கள் பின்வருமாறு:' : language === 'sinhala' ? 'ඔබගේ ඉදිරිපත් කිරීම් විස්තර පහත පරිදි වේ:' :
                        'Your submission details are as follows:'}
                </h4>
                <h3>
                    <span className='lable'>Attemped Date & Time: </span>
                    <span className='value'>{assessmentData?.attempt?.createdAt}</span>
                </h3>
                <h3>
                    <span className='lable'>
                        Submission URL:
                    </span>
                    <span className='value'>
                        <a
                            href={assessmentData?.attempt?.submissionURL}
                            target='_blank'
                            rel='noreferrer'
                            className='value'>
                            {assessmentData?.attempt?.submissionURL}
                        </a>
                    </span>
                </h3>
                <h3>
                    <span className='lable'>
                        Is Reviewed:
                    </span>
                    <span className='value'>
                        {assessmentData?.attempt?.isReviewed ? 'Yes' : 'No'}
                    </span>
                </h3>
                {assessmentData?.attempt?.isReviewed &&
                    <>
                        <h3>
                            <span className='lable'>
                                Reviewed Date:
                            </span>
                            <span className='value'>
                                {assessmentData?.attempt?.reviewedDate}
                            </span>
                        </h3>
                        <h3>
                            <span className='lable'>
                                Reviewed By:
                            </span>
                            <span className='value'>
                                {name}
                            </span>
                        </h3>
                        <h3>
                            <span className='lable'>
                                Marks:
                            </span>
                            <span className='value'>
                                {assessmentData?.attempt?.marks}
                            </span>
                        </h3>

                    </>
                }
                {attempts > 0 &&
                    <>
                        <Divider />
                        <div className='re-submit'>
                            <h4>
                                If you want to re-submit the assessment, please provide the submission URL below:
                            </h4>
                            <p className='instruct'>
                                {language === 'tamil' ? 'வினாடி வினா அல்லது சோதனை சமர்ப்பிப்புகளுக்கு, மதிப்பீட்டின் போது பயன்படுத்தப்படும் மின்னஞ்சல் முகவரியை நியமிக்கப்பட்ட சமர்ப்பிப்பு URL புலத்தில் சேர்க்கவும். சமர்ப்பிப்பு ஒரு திட்டம் அல்லது பணிக்கு தொடர்புடையதாக இருந்தால், தயவுசெய்து திட்டவட்டத்தின் பொது அணுகக்கூடிய URL அல்லது குறிப்பிட்ட சமர்ப்பிப்பு URL புலத்தில் வழங்கவும்.' : language === 'sinhala' ? 'ප්‍රශ්නාවලිය හෝ පරීක්ෂණ ඉදිරිපත් කිරීම් සඳහා, කරුණාකර නම් කරන ලද ඉදිරිපත් කිරීම් URL ක්ෂේත්‍රයේ තක්සේරුවේදී භාවිතා කරන විද්‍යුත් තැපැල් ලිපිනය ඇතුළත් කරන්න. ඉදිරිපත් කිරීම ව්‍යාපෘතියකට හෝ පැවරුමකට අදාළ නම්, කාරුණිකව ව්‍යාපෘතියේ ප්‍රසිද්ධියේ ප්‍රවේශ විය හැකි URL එක හෝ නිශ්චිත ඉදිරිපත් කිරීමේ URL ක්ෂේත්‍රයේ පැවරුම ලබා දෙන්න.' :
                                    'For quiz or test submissions, please include the email address used during the assessment in the designated submission URL field. If the submission pertains to a project or assignment, kindly provide the publicly accessible URL of the project or assignment in the specified submission URL field.'}
                            </p>
                            <Form
                                layout="vertical"
                                onFinish={onFinishReSubmit}
                                ref={formRef}
                            >
                                <Form.Item
                                    label={language === 'tamil' ? 'சமர்ப்பிப்பு URL' : language === 'sinhala' ? 'ඉදිරිපත් කිරීමේ URL' : 'Submission URL'}
                                    name="submissionURL"
                                    rules={[{
                                        required: true,
                                        message: 'Please enter submission URL!'
                                    }]}>
                                    <Input
                                        placeholder={language === 'tamil' ? 'சமர்ப்பிப்பு URL ஐ உள்ளிடவும்' : language === 'sinhala' ? 'ඉදිරිපත් කිරීමේ URL ඇතුලත් කරන්න' : '"Please enter submission URL"'}
                                    />

                                </Form.Item>


                                <Button
                                    loading={isLoading}
                                    type="primary" htmlType="submit">
                                    {language === 'tamil' ? 'மதிப்பீட்டை சமர்ப்பிக்கவும்' : language === 'sinhala' ? 'ඇගයීම ඉදිරිපත් කරන්න' : 'Re-submit Assessment'}
                                </Button>

                            </Form>
                        </div>
                    </>}
            </div>
            }

            {!assessmentData?.attempt &&
                <>
                    <h4>
                        {language === 'tamil' ? 'தயவுசெய்து வழிமுறைகளை கவனமாகப் படியுங்கள்' : language === 'sinhala' ? 'කරුණාකර උපදෙස් හොඳින් කියවන්න' :
                            'Please Read the Instructions Carefully'}
                    </h4>
                    <p className='instruct'>
                        {language === 'tamil' ? 'வினாடி வினா அல்லது சோதனை சமர்ப்பிப்புகளுக்கு, மதிப்பீட்டின் போது பயன்படுத்தப்படும் மின்னஞ்சல் முகவரியை நியமிக்கப்பட்ட சமர்ப்பிப்பு URL புலத்தில் சேர்க்கவும். சமர்ப்பிப்பு ஒரு திட்டம் அல்லது பணிக்கு தொடர்புடையதாக இருந்தால், தயவுசெய்து திட்டவட்டத்தின் பொது அணுகக்கூடிய URL அல்லது குறிப்பிட்ட சமர்ப்பிப்பு URL புலத்தில் வழங்கவும்.' : language === 'sinhala' ? 'ප්‍රශ්නාවලිය හෝ පරීක්ෂණ ඉදිරිපත් කිරීම් සඳහා, කරුණාකර නම් කරන ලද ඉදිරිපත් කිරීම් URL ක්ෂේත්‍රයේ තක්සේරුවේදී භාවිතා කරන විද්‍යුත් තැපැල් ලිපිනය ඇතුළත් කරන්න. ඉදිරිපත් කිරීම ව්‍යාපෘතියකට හෝ පැවරුමකට අදාළ නම්, කාරුණිකව ව්‍යාපෘතියේ ප්‍රසිද්ධියේ ප්‍රවේශ විය හැකි URL එක හෝ නිශ්චිත ඉදිරිපත් කිරීමේ URL ක්ෂේත්‍රයේ පැවරුම ලබා දෙන්න.' :
                            'For quiz or test submissions, please include the email address used during the assessment in the designated submission URL field. If the submission pertains to a project or assignment, kindly provide the publicly accessible URL of the project or assignment in the specified submission URL field.'}
                    </p>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label={language === 'tamil' ? 'சமர்ப்பிப்பு URL' : language === 'sinhala' ? 'ඉදිරිපත් කිරීමේ URL' : 'Submission URL'}
                            name="submissionURL"
                            rules={[{
                                required: true,
                                message: 'Please enter submission URL!'
                            }]}>
                            <Input
                                placeholder={language === 'tamil' ? 'சமர்ப்பிப்பு URL ஐ உள்ளிடவும்' : language === 'sinhala' ? 'ඉදිරිපත් කිරීමේ URL ඇතුලත් කරන්න' : '"Please enter submission URL"'}
                            />

                        </Form.Item>


                        <Button
                            loading={isLoading}
                            type="primary" htmlType="submit">
                            {language === 'tamil' ? 'மதிப்பீட்டை சமர்ப்பிக்கவும்' : language === 'sinhala' ? 'ඇගයීම ඉදිරිපත් කරන්න' : 'Submit Assessment'}
                        </Button>

                    </Form>
                </>}
        </div>
    )
}

export default SubmitAssessment