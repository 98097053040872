import { createSlice } from "@reduxjs/toolkit";

const allCentersReducer = createSlice({
    name: "allCenters",
    initialState: {
        allCenters: [],
        isLoading: false,
        error: null
    },
    reducers: {
        setAllCenters: (state, action) => {
            state.allCenters = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllCenters, setIsLoading, setError } = allCentersReducer.actions

export default allCentersReducer.reducer;