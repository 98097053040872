import { createSlice } from '@reduxjs/toolkit';

const centerGroupsReducer = createSlice({
    name: 'centerGroups',
    initialState: {
        centerGroups: [],
        isLoading: false,
        error: null
    },
    reducers: {
        setCenterGroups: (state, action) => {
            state.centerGroups = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setCenterGroups, setIsLoading, setError } = centerGroupsReducer.actions

export default centerGroupsReducer.reducer