import React, { useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin } from 'antd';

// API
import { addCenter } from '../../../../Constants/Api/functions/function';

// Redux
import { useSelector } from 'react-redux';

import { DISTRICTS } from '../../../../Constants/Districts/districts';

const { Option } = Select;

const AddSchool = ({ setOpen, isOpen, handleGetAllCenters }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector((state) => state.userProfile.userProfile);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.createdBy = userProfile.id;

        setIsLoading(true)
        try {
            const response = await addCenter(values);
            if (response.status === 200) {
                openNotificationWithIcon('success')
                setIsLoading(false)
                setOpen(false)
                handleGetAllCenters()
            } else {
                openNotificationWithIcon('error', response.data)
                setIsLoading(false)
            }
        } catch (error) {
            openNotificationWithIcon('error', error)
            setIsLoading(false)
        }
    }

    const closeAddSchoolDrawer = () => {
        setOpen(false)
    }

    const openNotificationWithIcon = (type, error) => {
        api[type]({
            message: type === "success" ?
                "New Library Created"
                : "Error Creating User",
            description: type === "success"
                ? "New Library has been created successfully."
                : `There was an error creating library. Please try again later. ${error}`
        });
    };

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add new Library"
                width={720}
                onClose={closeAddSchoolDrawer}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Creating Library..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="Library Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter library's name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter library's name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="district"
                                    label="District"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select library district',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select library district"
                                    >
                                        {
                                            DISTRICTS.map((district) => (
                                                <Option value={district.val}>{district.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Library Address"
                                >
                                    <Input placeholder="Please enter library's address" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="contactNumber"
                                    label="Contact Number"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^0[0-9]{9}$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="0XX XXXX XXX" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The email is not a valid!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Please enter library email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="mapLocation"
                                    label="Map Location"
                                >
                                    <Input placeholder="Please enter library map location" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    Add Library
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddSchool