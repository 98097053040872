import { createSlice } from "@reduxjs/toolkit";

const allCourcesReducer = createSlice({
    name: "allCources",
    initialState: {
        allCources: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setAllCources: (state, action) => {
            state.allCources = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllCources, setIsLoading, setError } = allCourcesReducer.actions

export default allCourcesReducer.reducer;