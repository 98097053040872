import React from 'react'

// Antd
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';

import Home from '../../Home';
import Cources from '../../Cources';
import users from '../../Users';
import Schools from '../../Schools';

import './style.scss';
import Course from '../../Cources/Course';
import EditCourse from '../../Cources/Course/EditCourse';
import User from '../../Users/User';
import School from '../../Schools/School';
import Assessments from '../../Assessments';
import Assessment from '../../Assessments/Assessment';

const { Content } = Layout;

const DashboardContent = () => {
    return (
        <Content className='dashboard-content'>
            <div className='site-layout-background'>
                <Switch>
                    <Route path='/dashboard/home' component={Home} />
                    <Route path='/dashboard/profile' component={User} />
                    <Route path='/dashboard/users/:id' component={User} />
                    <Route path='/dashboard/users' component={users} />
                    <Route path='/dashboard/libraries/:id' component={School} />
                    <Route path='/dashboard/libraries' component={Schools} />
                    <Route path='/dashboard/cources/:id/edit' component={EditCourse} />
                    <Route path='/dashboard/cources/:id' component={Course} />
                    <Route path='/dashboard/cources' component={Cources} />
                    <Route path='/dashboard/assessments/:id' component={Assessment} />
                    <Route path='/dashboard/assessments' component={Assessments} />
                </Switch>
            </div>
        </Content>
    )
}

export default DashboardContent