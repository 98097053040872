import React, { useRef, useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin } from 'antd';

// API
import { addGroup } from '../../../../.././../Constants/Api/functions/function';

// Redux
import { useSelector } from 'react-redux';


const { Option } = Select;

const CreateGroup = ({ setOpen, isOpen, handleGetGroups, users, centerId, groups }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector((state) => state.userProfile.userProfile);
    const formRef = useRef();

    const onFinish = async (values) => {
        let group = {
            name: values.name,
            createdBy: userProfile.id,
            centerId: centerId,
            ...(values.leaderId ? { leaderId: values.leaderId } : {}),
        }

        setIsLoading(true)
        try {
            const response = await addGroup(group);
            if (response.status === 200) {
                openNotificationWithIcon('success')
                clearForm()
                setIsLoading(false)
                setOpen(false)
                handleGetGroups()
            } else {
                openNotificationWithIcon('error', response.data)
                setIsLoading(false)
            }
        } catch (error) {
            openNotificationWithIcon('error', error)
            setIsLoading(false)
        }
    }

    const clearForm = () => {
        formRef.current.resetFields();
    }

    const closeAddSchoolDrawer = () => {
        setOpen(false)
    }

    const openNotificationWithIcon = (type, error) => {
        api[type]({
            message: type === "success" ?
                "New group created successfully"
                : "Error creating group",
            description: type === "success"
                ? "New group created successfully in this school."
                : `There was an error creating group. Please try again later. ${error}`
        });
    };

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Create new group"
                width={720}
                onClose={closeAddSchoolDrawer}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Creating group..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="School Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter school's name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter school's name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="leaderId"
                                    label="Leader"
                                >
                                    <Select
                                        placeholder="Please select an leader"
                                    >
                                        {users
                                            .filter(user => !groups.some(group => group.leaderId === user.id))
                                            .map(user => (
                                                <Option value={user.id}>{user.name}</Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    Create Group
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default CreateGroup