import { configureStore, combineReducers } from '@reduxjs/toolkit'

import userProfileReducer from './App/Reducers/userProfileReducer'
import allUsersReducer from './App/Reducers/allUsersReducer'
import allCentersReducer from './App/Reducers/allCentersReducer'
import centerGroupsReducer from './App/Reducers/centerGroupsReducer'
import allCourcesReducer from './App/Reducers/allCourcesReducer'
import allSessonsReducer from './App/Reducers/allSessonsReducer'
import noteReducer from './App/Reducers/noteReducer'
import allAssessmentsReducer from './App/Reducers/allAssessmentsReducer'

const rootReducer = combineReducers({
    userProfile: userProfileReducer,
    allUsers: allUsersReducer,
    allCenters: allCentersReducer,
    centerGroups: centerGroupsReducer,
    allCources: allCourcesReducer,
    allSessons: allSessonsReducer,
    note: noteReducer,
    allAssessments: allAssessmentsReducer,
})

const store = configureStore({
    reducer: rootReducer
})

export default store