import React, { useRef, useState } from 'react'

import { useSelector } from 'react-redux';

import { reviewAssestmentSubmission } from '../../../../../../Constants/Api/functions/function';

// Antd
import { Button, Divider, Drawer, Form, Input, notification, Spin } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss';


const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const ReviewSubmission = ({
    isOpen,
    setOpen,
    handleGetSubmissions,
    submissionData,
    userData,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const formRef = useRef();


    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.id = submissionData.id;
        values.reviewedBy = userProfile.id;

        setIsLoading(true);

        try {
            const res = await reviewAssestmentSubmission(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'Submission reviewed successfully'
                );
                setOpen(false);
                await handleGetSubmissions();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Review Submission"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                className='submission-review-drawer'
            >
                <div className='submission-details'>
                    <h2>
                        <span className='id'>
                            ID:
                        </span>
                        <span>
                            {submissionData?.id}
                        </span>
                    </h2>
                    <h2>
                        <span className='id'>
                            Student Name:
                        </span>

                        <span>
                            {userData?.name}
                        </span>
                    </h2>
                    <h2>
                        <span className='id'>
                            Submitted URL:
                        </span>

                        <span>
                            {submissionData?.submissionURL}
                        </span>
                    </h2>
                    <h2>
                        <span className='id'>
                            Submitted At:
                        </span>

                        <span>
                            {submissionData?.createdAt}
                        </span>
                    </h2>
                    <h2>
                        <span className='id'>
                            Attempt No:
                        </span>

                        <span>
                            {submissionData?.attemptNumber}
                        </span>
                    </h2>
                </div>
                <Divider />
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label="Marks"
                            name="marks"
                            rules={[{ required: true, message: 'Please enter marks!' }]}>
                            <Input
                                type='number'
                                placeholder="Please enter marks"
                                min={0}
                                max={100}
                            />

                        </Form.Item>
                        <Form.Item
                            label="Comments"
                            name="comments"
                        >
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="Please enter comments"
                                style={{
                                    height: '100%',
                                    zIndex: 10000,
                                }}
                            />
                        </Form.Item>

                        <Button
                            type="primary" htmlType="submit">
                            Submit Review
                        </Button>

                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default ReviewSubmission