import { createSlice } from '@reduxjs/toolkit';

const noteReducer = createSlice({
    name: 'note',
    initialState: {
        note: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setNote: (state, action) => {
            state.note = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setNote, setIsLoading, setError } = noteReducer.actions

export default noteReducer.reducer